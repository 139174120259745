exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-categories-json-slug-tsx": () => import("./../../../src/pages/{CategoriesJson.slug}.tsx" /* webpackChunkName: "component---src-pages-categories-json-slug-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-barn-conversion-oxfordshire-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/barn-conversion-oxfordshire/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-barn-conversion-oxfordshire-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-barn-conversion-south-oxfordshire-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/barn-conversion-south-oxfordshire/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-barn-conversion-south-oxfordshire-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-cane-end-stables-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/cane-end-stables/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-cane-end-stables-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-commercial-barn-conversion-harwell-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/commercial-barn-conversion-harwell/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-commercial-barn-conversion-harwell-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-commercial-farmyard-redevelopment-wheatley-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/commercial-farmyard-redevelopment-wheatley/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-commercial-farmyard-redevelopment-wheatley-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-crowmarsh-granary-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/crowmarsh-granary/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-crowmarsh-granary-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-crowmarsh-stables-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/crowmarsh-stables/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-crowmarsh-stables-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-dutch-barn-conversion-berkshire-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/dutch-barn-conversion-berkshire/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-dutch-barn-conversion-berkshire-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-family-house-berkshire-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/family-house-berkshire/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-family-house-berkshire-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-family-house-henley-on-thames-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/family-house-henley-on-thames/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-family-house-henley-on-thames-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-family-house-oxfordshire-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/family-house-oxfordshire/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-family-house-oxfordshire-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-farmhouse-reimagined-pangbourne-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/farmhouse-reimagined-pangbourne/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-farmhouse-reimagined-pangbourne-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-garden-pavilion-pangbourne-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/garden-pavilion-pangbourne/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-garden-pavilion-pangbourne-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-garden-room-hurley-on-thames-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/garden-room-hurley-on-thames/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-garden-room-hurley-on-thames-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-gym-and-annex-west-oxford-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/gym-and-annex-west-oxford/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-gym-and-annex-west-oxford-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-holiday-cottage-herefordshire-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/holiday-cottage-herefordshire/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-holiday-cottage-herefordshire-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-home-remodel-oxfordshire-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/home-remodel-oxfordshire/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-home-remodel-oxfordshire-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-hospital-conversion-south-oxfordshire-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/hospital-conversion-south-oxfordshire/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-hospital-conversion-south-oxfordshire-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-listed-house-oxfordshire-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/listed-house-oxfordshire/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-listed-house-oxfordshire-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-london-road-reading-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/london-road-reading/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-london-road-reading-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-music-room-rotherfield-greys-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/music-room-rotherfield-greys/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-music-room-rotherfield-greys-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-new-barn-lower-shiplake-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/new-barn-lower-shiplake/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-new-barn-lower-shiplake-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-other-family-homes-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/other-family-homes/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-other-family-homes-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-plasterers-arms-oxford-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/plasterers-arms-oxford/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-plasterers-arms-oxford-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-private-dwelling-kintbury-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/private-dwelling-kintbury/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-private-dwelling-kintbury-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-railway-works-redevelopment-swindon-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/railway-works-redevelopment-swindon/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-railway-works-redevelopment-swindon-index-md" */),
  "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-rural-dwelling-vale-ridgeway-index-md": () => import("./../../../src/pages/{ProjectsMdx.frontmatter__category}/{ProjectsMdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/woodfieldbrady/woodfieldbrady/src/data/projects/rural-dwelling-vale-ridgeway/index.md" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-category-projects-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-woodfieldbrady-woodfieldbrady-src-data-projects-rural-dwelling-vale-ridgeway-index-md" */)
}

