/**
 * Do not edit directly
 * Generated on Fri, 22 Mar 2024 12:56:16 GMT
 */

module.exports = {
  "wb": {
    "color": {
      "cardinal": {
        "value": "#D2232A",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#D2232A"
        },
        "name": "WbColorCardinal",
        "attributes": {
          "category": "wb",
          "type": "color",
          "item": "cardinal"
        },
        "path": [
          "wb",
          "color",
          "cardinal"
        ]
      },
      "sangria": {
        "value": "#930004",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#930004"
        },
        "name": "WbColorSangria",
        "attributes": {
          "category": "wb",
          "type": "color",
          "item": "sangria"
        },
        "path": [
          "wb",
          "color",
          "sangria"
        ]
      },
      "white": {
        "value": "#FFFFFF",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFFFFF"
        },
        "name": "WbColorWhite",
        "attributes": {
          "category": "wb",
          "type": "color",
          "item": "white"
        },
        "path": [
          "wb",
          "color",
          "white"
        ]
      },
      "ebb": {
        "value": "#EDEAEA",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#EDEAEA"
        },
        "name": "WbColorEbb",
        "attributes": {
          "category": "wb",
          "type": "color",
          "item": "ebb"
        },
        "path": [
          "wb",
          "color",
          "ebb"
        ]
      },
      "alto": {
        "value": "#DDDDDD",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#DDDDDD"
        },
        "name": "WbColorAlto",
        "attributes": {
          "category": "wb",
          "type": "color",
          "item": "alto"
        },
        "path": [
          "wb",
          "color",
          "alto"
        ]
      },
      "mineShaft": {
        "value": "#353535",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#353535"
        },
        "name": "WbColorMineShaft",
        "attributes": {
          "category": "wb",
          "type": "color",
          "item": "mineShaft"
        },
        "path": [
          "wb",
          "color",
          "mineShaft"
        ]
      },
      "black": {
        "value": "#000000",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#000000"
        },
        "name": "WbColorBlack",
        "attributes": {
          "category": "wb",
          "type": "color",
          "item": "black"
        },
        "path": [
          "wb",
          "color",
          "black"
        ]
      }
    },
    "size": {
      "headerHeight": {
        "value": "75px",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "75px"
        },
        "name": "WbSizeHeaderHeight",
        "attributes": {
          "category": "wb",
          "type": "size",
          "item": "headerHeight"
        },
        "path": [
          "wb",
          "size",
          "headerHeight"
        ]
      },
      "largeGap": {
        "value": "32px",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "32px"
        },
        "name": "WbSizeLargeGap",
        "attributes": {
          "category": "wb",
          "type": "size",
          "item": "largeGap"
        },
        "path": [
          "wb",
          "size",
          "largeGap"
        ]
      },
      "smallGap": {
        "value": "6px",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "6px"
        },
        "name": "WbSizeSmallGap",
        "attributes": {
          "category": "wb",
          "type": "size",
          "item": "smallGap"
        },
        "path": [
          "wb",
          "size",
          "smallGap"
        ]
      },
      "horizontalMargin": {
        "mobileOnly": {
          "value": "2rem",
          "filePath": "src/tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "2rem"
          },
          "name": "WbSizeHorizontalMarginMobileOnly",
          "attributes": {
            "category": "wb",
            "type": "size",
            "item": "horizontalMargin",
            "subitem": "mobileOnly"
          },
          "path": [
            "wb",
            "size",
            "horizontalMargin",
            "mobileOnly"
          ]
        },
        "tabletPortraitUp": {
          "value": "5vw",
          "filePath": "src/tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "5vw"
          },
          "name": "WbSizeHorizontalMarginTabletPortraitUp",
          "attributes": {
            "category": "wb",
            "type": "size",
            "item": "horizontalMargin",
            "subitem": "tabletPortraitUp"
          },
          "path": [
            "wb",
            "size",
            "horizontalMargin",
            "tabletPortraitUp"
          ]
        },
        "desktopUp": {
          "value": "10vw",
          "filePath": "src/tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "10vw"
          },
          "name": "WbSizeHorizontalMarginDesktopUp",
          "attributes": {
            "category": "wb",
            "type": "size",
            "item": "horizontalMargin",
            "subitem": "desktopUp"
          },
          "path": [
            "wb",
            "size",
            "horizontalMargin",
            "desktopUp"
          ]
        },
        "desktopLargeUp": {
          "value": "15vw",
          "filePath": "src/tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "15vw"
          },
          "name": "WbSizeHorizontalMarginDesktopLargeUp",
          "attributes": {
            "category": "wb",
            "type": "size",
            "item": "horizontalMargin",
            "subitem": "desktopLargeUp"
          },
          "path": [
            "wb",
            "size",
            "horizontalMargin",
            "desktopLargeUp"
          ]
        }
      }
    },
    "breakpoint": {
      "mobileOnly": {
        "value": "max-width: 599px",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "max-width: 599px"
        },
        "name": "WbBreakpointMobileOnly",
        "attributes": {
          "category": "wb",
          "type": "breakpoint",
          "item": "mobileOnly"
        },
        "path": [
          "wb",
          "breakpoint",
          "mobileOnly"
        ]
      },
      "tabletPortraitUp": {
        "value": "min-width: 600px",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "min-width: 600px"
        },
        "name": "WbBreakpointTabletPortraitUp",
        "attributes": {
          "category": "wb",
          "type": "breakpoint",
          "item": "tabletPortraitUp"
        },
        "path": [
          "wb",
          "breakpoint",
          "tabletPortraitUp"
        ]
      },
      "tabletPortraitDown": {
        "value": "max-width: 899px",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "max-width: 899px"
        },
        "name": "WbBreakpointTabletPortraitDown",
        "attributes": {
          "category": "wb",
          "type": "breakpoint",
          "item": "tabletPortraitDown"
        },
        "path": [
          "wb",
          "breakpoint",
          "tabletPortraitDown"
        ]
      },
      "tabletLandscapeUp": {
        "value": "min-width: 900px",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "min-width: 900px"
        },
        "name": "WbBreakpointTabletLandscapeUp",
        "attributes": {
          "category": "wb",
          "type": "breakpoint",
          "item": "tabletLandscapeUp"
        },
        "path": [
          "wb",
          "breakpoint",
          "tabletLandscapeUp"
        ]
      },
      "desktopUp": {
        "value": "min-width: 1200px",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "min-width: 1200px"
        },
        "name": "WbBreakpointDesktopUp",
        "attributes": {
          "category": "wb",
          "type": "breakpoint",
          "item": "desktopUp"
        },
        "path": [
          "wb",
          "breakpoint",
          "desktopUp"
        ]
      },
      "desktopLargeUp": {
        "value": "min-width: 1800px",
        "filePath": "src/tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "min-width: 1800px"
        },
        "name": "WbBreakpointDesktopLargeUp",
        "attributes": {
          "category": "wb",
          "type": "breakpoint",
          "item": "desktopLargeUp"
        },
        "path": [
          "wb",
          "breakpoint",
          "desktopLargeUp"
        ]
      }
    }
  }
};
