import styled, { css } from "styled-components";

import Tokens from "../../tokens/build/tokens.js";

const defaultSizes = css`
  @media (${Tokens.wb.breakpoint.mobileOnly.value}) {
    font-size: 1.4em;
  }

  @media (${Tokens.wb.breakpoint.tabletPortraitUp.value}) {
    font-size: 1.6em;
  }

  @media (${Tokens.wb.breakpoint.desktopUp.value}) {
    font-size: 2em;
  }

  @media (${Tokens.wb.breakpoint.desktopLargeUp.value}) {
    font-size: 2.5em;
  }
`;

const largeSizes = css`
  @media (${Tokens.wb.breakpoint.mobileOnly.value}) {
    font-size: 2em;
  }

  @media (${Tokens.wb.breakpoint.tabletPortraitUp.value}) {
    font-size: 2.5em;
  }

  @media (${Tokens.wb.breakpoint.tabletLandscapeUp.value}) {
    font-size: 3em;
  }

  @media (${Tokens.wb.breakpoint.desktopUp.value}) {
    font-size: 3.5em;
  }

  @media (${Tokens.wb.breakpoint.desktopLargeUp.value}) {
    font-size: 4em;
  }
`;

const smallSizes = css`
  @media (${Tokens.wb.breakpoint.mobileOnly.value}) {
    font-size: 2em;
  }

  @media (${Tokens.wb.breakpoint.tabletPortraitUp.value}) {
    font-size: 2em;
  }

  @media (${Tokens.wb.breakpoint.tabletLandscapeUp.value}) {
    font-size: 1.5em;
  }

  @media (${Tokens.wb.breakpoint.desktopUp.value}) {
    font-size: 1.8em;
  }

  @media (${Tokens.wb.breakpoint.desktopLargeUp.value}) {
    font-size: 2em;
  }
`;

const extraSmallSizes = css`
  @media (${Tokens.wb.breakpoint.mobileOnly.value}) {
    font-size: 1.2em;
  }

  @media (${Tokens.wb.breakpoint.tabletPortraitUp.value}) {
    font-size: 1.2em;
  }
`;

interface HeadingProps {
  $large?: boolean;
  $small?: boolean;
  $extraSmall?: boolean;
}

const Heading = styled.h1<HeadingProps>`
  text-transform: uppercase;
  font-weight: 700;
  margin: 0.3em 0;
  color: inherit;

  ${defaultSizes}

  ${props => props.$large && largeSizes}
  ${props => props.$small && smallSizes}
  ${props => props.$extraSmall && extraSmallSizes}
`;

export default Heading;
