import React from "react";
import styled from "styled-components";

interface ParagraphProps {
  $fontWeight?: number;
}

const Paragraph: React.FC<React.PropsWithChildren<ParagraphProps>> =
  styled.p<ParagraphProps>`
    line-height: 1.5em;
    font-weight: ${props => props.$fontWeight || 300};
  `;

export default Paragraph;
