module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-0d8bdfa6d3/0/cache/gatsby-plugin-styled-components-npm-6.13.1-a700bfd5f0-8b8b595f81.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-067c33061c/0/cache/gatsby-plugin-google-analytics-npm-5.9.0-629577e942-06131e2197.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-30667421-20","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-typography-virtual-2eccd44798/0/cache/gatsby-plugin-typography-npm-5.7.0-be6dd422a2-5fe4345f0c.zip/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-ad247d024b/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
